import React, { FC, useState } from 'react'
import SALink from 'components/SALink'
import SaatchiLogo from 'svg/saatchi-only-logo.svg'
import SaatchiTypography from 'svg/saatchi-typography.svg'
import styles from './MailSection.module.scss'
import SocialMedia from './SocialMedia'
import InputArrow from 'svg/input-arrow.svg'
import { newsletterSignupApi } from 'apis/newsletter'
import { REQUEST_SOURCE } from 'server/constants/newsletter_request_sources'
import classNames from 'classnames'
import { emailValidator } from 'lib/validation'

type MailSectionProps = {
  isStudio?: boolean
}

const MailSection: FC<MailSectionProps> = ({ isStudio }) => {
  const [apiStatus, updateApisStatus] = useState({
    message: '',
    hasError: false,
  })
  const [email, setEmail] = useState('')
  const { message, hasError } = apiStatus
  const hideNewsletterForm = false

  const handleSubscribe = async () => {
    if (email === '') {
      updateApisStatus({
        message: 'Please enter an email address',
        hasError: true,
      })
      return
    }

    if (!emailValidator.validate(email)) {
      updateApisStatus({
        message: 'Please enter a valid email',
        hasError: true,
      })
      return
    }

    try {
      const { success, messages } = await newsletterSignupApi({
        email,
        requestSource: REQUEST_SOURCE.EASEL_FOOTER,
        sourceURL: window.location.href,
      })

      if (success === true) {
        updateApisStatus({
          message: `Great! You've been subscribed.`,
          hasError: false,
        })

        if (window.dataLayer) {
          window.dataLayer.push({
            event: 'newsletter signup success',
            email_signup_source: REQUEST_SOURCE.EASEL_FOOTER,
          })
        }
      } else {
        updateApisStatus({
          message: messages[0],
          hasError: true,
        })
      }
    } catch (error) {
      console.error('Newletter API error:', error)
      updateApisStatus({
        message: 'Sorry unexpected error, try again in some minutes',
        hasError: true,
      })
    }
  }

  const handleSubmit = (ev: React.SyntheticEvent<Record<string, any>>) => {
    ev.preventDefault()
    handleSubscribe()
  }

  const onChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value || '')
  }

  const handleEnterPress = (e) => {
    if (e.key === 'Enter') {
      handleSubmit(e)
    }
  }

  if (hideNewsletterForm) {
    return null
  }

  return (
    <div className={styles.mailContainer}>
      <SALink
        className={styles.footerLogo}
        alt='Saatchi Art logo footer'
        aria-label='Saatchi Art logo footer'
        data-type='logo-footer'
        data-gtm-event='footer'
        href={process.env.SITE_URL || '/'}
      >
        <SaatchiLogo className={styles.mobileSaLogoIcon} />
        <SaatchiTypography className={styles.mobileSaLogoTypography} />
      </SALink>
      {!isStudio && (
        <div className={styles.joinMailingContainer}>
          <div className={styles.joinMailingBox}>
            <span className='defaultTypographyH6'>Sign Up to Receive 10% Off Your First Order</span>
            <p className='defaultTypographySmall'>
              Discover new art and collections added weekly by our curators.
            </p>
          </div>
          <div className={styles.joinMailingInputContainer}>
            {message && !hasError ? (
              <p data-type='subscribe-message' className={styles.emailSubscribeMessage}>
                {message}
              </p>
            ) : (
              <div
                className={classNames(styles.joinMailingInputBox, {
                  [styles.joinMailingInputBoxError]: hasError,
                })}
              >
                <input
                  type='email'
                  name='email'
                  placeholder='Enter Email Address'
                  required
                  onChange={onChangeInput}
                  onKeyDown={handleEnterPress}
                  value={email}
                  aria-label='Email Address'
                />
                <div
                  className={styles.joinMailingInputButton}
                  data-type='email-subscription-submit'
                  onClick={handleSubmit}
                >
                  <InputArrow />
                </div>
              </div>
            )}
            {hasError && (
              <span className={classNames(styles.emailError, 'defaultTypographyXSmall')}>
                {message}
              </span>
            )}
          </div>
        </div>
      )}
      <SocialMedia />
    </div>
  )
}

export default MailSection
