import React, { FC, useState } from 'react'
import CheckIcon from 'svg/tick-redesign.svg'
import PasswordInput from './PasswordInput'
import { RULES } from '../helpers'
import styles from '../Modals/Auth.module.scss'
import classNames from 'classnames'

const {
  COUNT_REGEX,
  DIGIT_REGEX,
  SPECIAL_CHARACTER_REGEX,
  LOWERCASE_REGEX,
  UPPERCASE_REGEX,
  PASSWORD_REGEX,
} = RULES

const validatePassword = (value: string): Record<string, any> => {
  return {
    count: !!value.match(COUNT_REGEX),
    digit: !!value.match(DIGIT_REGEX),
    lowercase: !!value.match(LOWERCASE_REGEX),
    password: !!value.match(PASSWORD_REGEX),
    special: !!value.match(SPECIAL_CHARACTER_REGEX),
    uppercase: !!value.match(UPPERCASE_REGEX),
  }
}

const DEFAULT_STATE = {
  rules: {
    count: false,
    digit: false,
    lowercase: false,
    password: false,
    special: false,
    uppercase: false,
  },
}

type PasswordCreationProps = {
  onChange: (...args: Array<any>) => any
  password: string
  passwordError: string
  styleOverride?: string
}

const PasswordCreation: FC<PasswordCreationProps> = (props) => {
  const { onChange, password, passwordError, styleOverride } = props
  const [state, handleState] = useState({ ...DEFAULT_STATE })
  const { rules } = state
  const isValid = !!rules.password

  const updateInput = (event: React.SyntheticEvent<HTMLElement>): void => {
    const { value } = event.target
    handleState({ ...state, password: value })
    // inputDebounce(() => {
    const validatedRules = validatePassword(value)
    handleState({ ...state, rules: validatedRules })

    if (onChange) {
      onChange({
        isValid,
        value,
      })
    } // }, 500)
  }

  return (
    <div className={styles.passwordCreation}>
      <PasswordInput
        name='create-password'
        password={password}
        title='Create Password'
        onChange={updateInput}
        passwordError={passwordError}
        styleOverride={styleOverride}
      />

      <ul
        className={classNames(
          {
            [styles.passwordRulesEmpty]: !password,
          },
          styles.passwordRules
        )}
      >
        <li
          className={classNames(
            {
              [styles.passwordRuleInValid]: !rules.uppercase,
            },
            'defaultTypographyXSmall',
            styles.passwordRule
          )}
        >
          <CheckIcon />
          <span>1 uppercase</span>
        </li>
        <li
          className={classNames(
            {
              [styles.passwordRuleInValid]: !rules.special,
            },
            'defaultTypographyXSmall',
            styles.passwordRule
          )}
        >
          <CheckIcon />
          <span>1 special character</span>
        </li>
        <li
          className={classNames(
            {
              [styles.passwordRuleInValid]: !rules.lowercase,
            },
            'defaultTypographyXSmall',
            styles.passwordRule
          )}
        >
          <CheckIcon />
          <span>1 lowercase</span>
        </li>
        <li
          className={classNames(
            {
              [styles.passwordRuleInValid]: !rules.count,
            },
            'defaultTypographyXSmall',
            styles.passwordRule
          )}
        >
          <CheckIcon />
          <span>Min. of 8 characters</span>
        </li>
        <li
          className={classNames(
            {
              [styles.passwordRuleInValid]: !rules.digit,
            },
            'defaultTypographyXSmall',
            styles.passwordRule
          )}
        >
          <CheckIcon />
          <span>1 number</span>
        </li>
      </ul>
    </div>
  )
}

export default PasswordCreation
