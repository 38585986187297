/*
Animation Components is meant to be a wrapper around elements/components
that you want to animate. It will allow you to do CSS animations on Mount and Unmount of Components
The main thing to make your app work is to pass in prop 'isVisible',
this is the Transition knows when and how you animate in/out.
*/
import classNames from 'classnames'
import React from 'react'
import { Transition, animated } from 'react-spring'
import { TimingAnimation, Easing } from 'react-spring/dist/addons.cjs'
type Props = {
  children:
    | (Array<React.ReactElement<React.ComponentProps<any>, any>> | null | undefined)
    | React.ReactElement<React.ComponentProps<any>, any>
  duration?: number
  easing?: string
  from?: Record<string, string | number>
  enter?: Record<string, string | number>
  update?: Record<string, string | number>
  leave?: Record<string, string | number>
  defaultStyles?: Record<string, string | number>
  isVisible?: boolean
  customClassname?: string
}
const Defaults = {
  duration: 350,
  easing: 'linear',
  from: {
    opacity: 0,
  },
  enter: {
    opacity: 1,
  },
  update: {
    opacity: 1,
  },
  leave: {
    opacity: 0,
  },
  defaultStyles: {},
}

const Component = (props: Props) => {
  if (typeof window === 'undefined') {
    return null
  }

  const { duration, easing, from, enter, update, leave, children, isVisible, defaultStyles } = {
    ...Defaults,
    ...props,
  }
  return (
    <Transition
      native
      from={from}
      enter={enter}
      update={update}
      leave={leave}
      impl={TimingAnimation}
      config={{
        duration,
        easing: Easing[easing],
      }}
    >
      {isVisible
        ? (style) => <animated.div style={{ ...style, ...defaultStyles }}>{children}</animated.div>
        : null}
    </Transition>
  )
}

export default Component
