const WEB = 'web'
const IOS = 'ios'
const LGTV = 'lgtv'

const REGISTRATION_SOURCE = {
  WEB,
  IOS,
  LGTV,
}

module.exports = { REGISTRATION_SOURCE }
