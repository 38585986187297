import { addStoreLocaleByCookie } from 'components/GeoLocation/helpers'
import { updateAppUrl } from 'lib/url/route'
import { stringToQueryObject, objectToQueryString } from 'lib/helpers'
const BROWSE = `/public/browse`
const CURATION = `/ops/curation`
const ARTIST_SEARCH = '/saatchi/artistSearch'
const VISII_SEARCH = '/public/visualSearch'

// const COLLECTIONS = `/saatchi/browse2`
// const USER_ARTWORKS = `/saatchi/browse2`
const defaultSearch = ({ query, type }: Record<string, any>) => {
  if (type === 'art') {
    const location = addStoreLocaleByCookie(`/all?query=${encodeURIComponent(query)}`)
    window.location.href = location
  } else {
    const location = addStoreLocaleByCookie(`/search/artist?query=${encodeURIComponent(query)}`)
    window.location.href = location
  }
}

// Custom Search Logic
const CUSTOM_SEARCH = [
  {
    page: BROWSE,
    delayed: true,
    action: ({ query, type }: Record<string, any>) => {
      if (type === 'art') {
        const { pathname, search } = window.location
        // We NEED to break down search and merge/overwrite QUERY
        const queryObject = stringToQueryObject(search)
        const href = `${pathname}?${objectToQueryString({
          ...queryObject,
          page: '',
          // Null values will be stripped, RESULT page=1
          query,
        })}`
        updateAppUrl({
          app: BROWSE,
          href,
        })
      } else {
        defaultSearch({
          query,
          type,
        })
      }
    },
  },
  {
    page: CURATION,
    delayed: true,
    action: ({ query, type }: Record<string, any>) => {
      if (type === 'art') {
        const { pathname, search } = window.location
        // We NEED to break down search and merge/overwrite QUERY
        const queryObject = stringToQueryObject(search)
        const href = `${pathname}?${objectToQueryString({
          ...queryObject,
          page: '',
          // Null values will be stripped, RESULT page=1
          query,
        })}`
        updateAppUrl({
          app: CURATION,
          href,
        })
      } else {
        defaultSearch({
          query,
          type,
        })
      }
    },
  },
  {
    page: ARTIST_SEARCH,
    delayed: true,
    action: ({ query, type }: Record<string, any>) => {
      if (type === 'artist') {
        const { pathname, search } = window.location
        // We NEED to break down search and merge/overwrite QUERY
        const queryObject = stringToQueryObject(search)
        const href = `${pathname}?${objectToQueryString({
          ...queryObject,
          page: '',
          // Null values will be stripped, RESULT page=1
          query,
        })}`
        updateAppUrl({
          app: ARTIST_SEARCH,
          href,
        })
      } else {
        defaultSearch({
          query,
          type,
        })
      }
    },
  },
  {
    page: VISII_SEARCH,
    delayed: true,
    action: ({ query, type }: Record<string, any>) => {
      defaultSearch({
        query,
        type,
      })
    },
  },
]

const getCurrentPagePath = () => {
  const { pathname } = window.location
  const { url: currentPagePath } = window.history.state || {}

  // Special case for Visii Search to avoid empty pathname
  if (!currentPagePath && !!pathname.match(/^(\/en-[A-Za-z]{2})?\/vsearch/)) {
    return VISII_SEARCH
  }

  // BlueCore from GTM is overridden the window.history.state
  return currentPagePath || BROWSE
}

const checkForCustomSearch = (): string => {
  const currentPagePath = getCurrentPagePath()
  // Grab all PAGES
  const pagesAvailable: Array<string> = CUSTOM_SEARCH.map(({ page }) => page)

  // Check if any match
  const currentPage = pagesAvailable.find((page) => currentPagePath.search(page) > -1)
  return currentPage || 'unknownPage'
}

const getCustomSearchDelayed = (currentPage: string): boolean => {
  const delayed = CUSTOM_SEARCH.find(({ page }) => page === currentPage)
  return Boolean(delayed) || false
}

const getCustomSearchAction = (
  currentPage: string
): ((...args: Array<any>) => any) | null | undefined => {
  const searchAction = CUSTOM_SEARCH.find(({ page }) => page === currentPage)
  return searchAction ? searchAction.action : null
}

// Custom Timer Logic for Search Input Field Updates
let timer
const DURATION = 2500 // 2.5 seconds

const clearTimer = () => {
  clearTimeout(timer)
  timer = null
}

const checkDelayedSearch = (): boolean => {
  const currentPage = checkForCustomSearch()
  return getCustomSearchDelayed(currentPage)
}

const delayedSearch = async (cb: (...args: Array<any>) => any): Promise<void> => {
  clearTimer()
  timer = setTimeout(() => {
    cb()
  }, DURATION)
}

const submitSearch = ({ query, type }: Record<string, any>) => {
  // If USER clicks enter, clear out any previous DELAYED searches
  clearTimer()
  // Make sure we do NOT have custom logic for a NEXT page
  const currentPage = checkForCustomSearch()

  if (currentPage) {
    const searchAction = getCustomSearchAction(currentPage)
    if (searchAction) {
      searchAction({
        query,
        type,
      })
      return
    }
  }

  defaultSearch({
    query,
    type,
  })
}

export { checkDelayedSearch, submitSearch, delayedSearch }
