import React from 'react'
import { fetchAllClientData } from 'apis/client'
import { setClientStorage } from 'store/middleware/client'
import { getCartData } from 'components/CartUrgencyBar/helper'
import Register from '../Forms/Register'
import Modal from 'components/SA/Modal/v2'
import styles from './Auth.module.scss'
import Close from 'svg/close-x.svg'

const Component = (
  props: Record<string, any>
): React.ReactElement<React.ComponentProps<any>, any> => {
  const { closeModal, loginModal, updateSession } = props

  const handleSubmit = async () => {
    // Fetch Session and Cart data
    const { payload: allClientData, success } = await fetchAllClientData()
    if (!success) {
      alert('Problem Fetching Session')
      return
    }
    setClientStorage(allClientData)
    await getCartData()
    const { cart, session } = allClientData
    updateSession({ cart: cart.count || 0, session })
    closeModal()
  }

  return (
    <Modal
      name='register'
      closeModal={closeModal}
      layout='standard'
      styleOverride={styles.authModal}
      fullTakeover={true}
    >
      <>
        <Close onClick={closeModal} className={styles.closeIcon} />
        <Register onClickLogIn={loginModal} onSubmit={handleSubmit} />
      </>
    </Modal>
  )
}

export default Component
