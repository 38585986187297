import React, { FC, useEffect, useState } from 'react'
import SALink from 'components/SALink'
import { newsletterSignupApi } from 'apis/newsletter'
import InputLabel from 'components/SA/FormElements/InputLabel'
import Recaptcha, { getCaptchaToken } from 'components/SA/FormElements/Recaptcha'
import Checkbox from 'components/Form/Checkbox'
import useFormData from 'hooks/formData'
import { getQueryObject } from 'lib/url/parse'
import FacebookLogin from './FacebookLogin'
import PasswordCreation from './PasswordCreation'
import { postLogin, postRegister } from '../apis'
import { registrationForm, registrationRules } from '../helpers'
import { REQUEST_SOURCE } from '../../../server/constants/newsletter_request_sources'
import { REGISTRATION_SOURCE } from '../../../server/constants/registration_sources'
import styles from '../Modals/Auth.module.scss'
import classNames from 'classnames'
import GeoLocationConnector, { ConnectorProps as GeoLocationProps } from 'connectors/GeoLocation'

type RegisterProps = {
  onSubmit: (...args: Array<any>) => any
  onClickLogIn: (...args: Array<any>) => any
} & GeoLocationProps

const Register: FC<RegisterProps> = (props) => {
  const {
    onClickLogIn,
    onSubmit,
    geoLocation: { userPreferencesCountry },
  } = props
  const [state, handleState] = useState({
    error: '',
    isBlocked: false,
    isTVScreen: false,
  })
  const { error, isBlocked, isTVScreen } = state
  const {
    formData,
    formErrors,
    onChangeInput,
    onChangeCheckbox,
    updateFormData,
    updateFormErrors,
    validateFormData,
  } = useFormData({
    initialState: registrationForm,
    formRules: registrationRules,
  })
  const { email, firstName, lastName, newsletterSignup, password } = formData
  const {
    email: emailError,
    firstName: firstNameError,
    lastName: lastNameError,
    password: passwordError,
  } = formErrors
  useEffect(() => {
    window.dataLayer.push({
      event: 'registration start',
    })

    if (window && window.location) {
      const query = getQueryObject(window.location.search)
      const { source } = query || {}
      handleState((prevProps) => ({ ...prevProps, isTVScreen: source === 'lgtv' }))
    }
  }, [])

  const handleSubmit = async (ev: React.SyntheticEvent<HTMLInputElement>) => {
    ev.preventDefault()
    const validForm = await validateFormData()

    if (!validForm) {
      return
    }

    handleState({ ...state, isBlocked: true, error: '' })
    const query = getQueryObject(window.location.search)
    const { auth_token: token, source, subscribe } = query || {}
    const registerCaptchaAction = 'register_submit'
    const registerCaptchaToken = await getCaptchaToken(registerCaptchaAction)
    const registrationSource =
      source === 'lgtv' ? REGISTRATION_SOURCE.LGTV : REGISTRATION_SOURCE.WEB
    const { messages, success } = await postRegister({
      captchaAction: registerCaptchaAction,
      captchaToken: registerCaptchaToken,
      email,
      firstName,
      lastName,
      password,
      registrationSource,
    })

    if (!success) {
      handleState({
        ...state,
        isBlocked: false,
        error: (messages || []).join(','), // Array[ 'error message' ]
      })
      window.dataLayer.push({
        event: 'registration error',
      })
      return
    }

    // Newsletter Signup
    if (newsletterSignup) {
      let requestSource = REQUEST_SOURCE.AUTH_REGISTRATION

      if (source === 'lgtv') {
        requestSource = REQUEST_SOURCE.LGTV_SIGNUP
      }

      const params = {
        email,
        requestSource,
        sourceURL: window.location.href,
      }
      await newsletterSignupApi(params)

      if (window.dataLayer) {
        window.dataLayer.push({
          event: 'newsletter signup success',
          email_signup_source: requestSource,
        })
      }
    }

    // We currently do NOT have a way to create SESSION via PALETTE
    // Once an ACCOUNT is created, login the USER with credentials they created
    const loginCaptchaAction = 'login_submit'
    const loginCaptchaToken = await getCaptchaToken(loginCaptchaAction)
    const { userEmail, userId, userType } = await postLogin(
      {
        captchaAction: loginCaptchaAction,
        captchaToken: loginCaptchaToken,
        email,
        password,
      },
      userPreferencesCountry
    )
    // Previously this dataLayer event happened before, but we need api data from `postLogin`
    window.dataLayer.push({
      event: 'registration success',
      'user category': userType,
      'user email': userEmail,
      'user id': userId,
    })

    // We need to check if USER is trying to login via LGTV
    if (source === 'lgtv') {
      if (subscribe) {
        window.location = `/tv/subscription`
      } else {
        window.location = `/tv/pin/${token || ''}`
      }

      return
    }

    onSubmit()
  }

  return (
    <form
      noValidate
      data-form='register'
      className={styles.registerForm}
      onSubmit={handleSubmit}
      data-type='register-form'
    >
      {isBlocked && <div className={styles.loader} />}

      <p className={`${styles.title} defaultTypographyH3`}>Welcome to Saatchi Art</p>

      <fieldset className='defaultFieldset'>
        <FacebookLogin
          handleIsBlocking={(blocking: boolean) => {
            handleState({ ...state, isBlocked: blocking })
          }}
          handleFbSession={() => {
            onSubmit()
          }}
        />

        <p className={`${styles.orText} defaultTypographyH6 defaultTextUppercase`}>or</p>

        <div className={styles.formInputContainer}>
          <InputLabel
            autoComplete='nope'
            error={firstNameError}
            type='text'
            name='firstName'
            value={firstName}
            title='First Name'
            onChange={(ev) => {
              onChangeInput(ev)
              updateFormErrors({ ...formErrors, firstName: '' })
            }}
            styleOverride={styles.authInputField}
          />

          <InputLabel
            autoComplete='nope'
            error={lastNameError}
            type='text'
            name='lastName'
            value={lastName}
            title='Last Name'
            onChange={(ev) => {
              onChangeInput(ev)
              updateFormErrors({ ...formErrors, lastName: '' })
            }}
            styleOverride={styles.authInputField}
          />

          <InputLabel
            autoComplete='nope'
            error={emailError}
            type='email'
            name='email'
            value={email}
            title='Email Address'
            onChange={(ev) => {
              onChangeInput(ev)
              updateFormErrors({ ...formErrors, email: '' })
            }}
            styleOverride={styles.authInputField}
          />

          <PasswordCreation
            onChange={({ isValid, value }) => {
              updateFormData({
                password: value,
              })
              updateFormErrors({ ...formErrors, password: '' })
            }}
            password={password}
            passwordError={passwordError}
            styleOverride={styles.authInputField}
          />

          <button
            type='submit'
            className={classNames(
              {
                [styles.buttonError]: passwordError || emailError,
              },
              'defaultButtonGunMetal'
            )}
            data-action='log-in'
          >
            <p className='defaultTypographyCtaLarge, defaultTextUppercase'>Register</p>
          </button>
        </div>
        {error && <p className={`${styles.error} defaultTypographyXSmall`}>{error}</p>}
      </fieldset>

      <div className={styles.newsletter}>
        <Checkbox
          name='newsletterSignup'
          value={1}
          checked={newsletterSignup}
          onChange={onChangeCheckbox}
          text='Receive updates on new curated collections, exclusive promotions, featured artists, and more.'
        />
      </div>

      {isTVScreen ? (
        <p className={styles.terms}>
          By registering, you agree to our amended{' '}
          <SALink href='https://www.saatchiart.com/terms' rel='noopener noreferrer' target='_blank'>
            Terms of Service
          </SALink>
          , new{' '}
          <SALink
            href='https://www.saatchiart.com/tv/terms'
            rel='noopener noreferrer'
            target='_blank'
          >
            Saatchi on TV Terms and Conditions of Use
          </SALink>{' '}
          and{' '}
          <SALink
            href='https://www.saatchiart.com/tv/privacy'
            rel='noopener noreferrer'
            target='_blank'
          >
            Saatchi on TV App Privacy Notice
          </SALink>
          .
        </p>
      ) : (
        <p className={`defaultTypographyTiny ${styles.terms}`}>
          By registering, you agree to our{' '}
          <SALink href='https://www.saatchiart.com/terms' rel='noopener noreferrer' target='_blank'>
            Terms of Service{' '}
          </SALink>{' '}
          and{' '}
          <SALink
            href='https://www.saatchiart.com/privacy'
            rel='noopener noreferrer'
            target='_blank'
          >
            Privacy Policy.
          </SALink>
        </p>
      )}

      <Recaptcha styleOverride={styles.loginRecaptcha} />

      <div className={styles.callout}>
        <span className='defaultTypographySmall'>Already have an account?</span>
        <button type='button' className={styles.calloutButton} onClick={onClickLogIn}>
          <p className='defaultTypographyCtaMedium defaultTextUppercase'>Log In</p>
        </button>
      </div>
    </form>
  )
}

export default GeoLocationConnector(Register)
