import React from 'react'
import Modal from 'components/SA/Modal/v2'
import styles from './Auth.module.scss'
import Close from 'svg/close-x.svg'
import ResetPassword from '../Forms/ResetPassword'

const Component = (
  props: Record<string, any>
): React.ReactElement<React.ComponentProps<any>, any> => {
  const { closeModal } = props
  return (
    <Modal name='reset' closeModal={closeModal} layout='standard' styleOverride={styles.authModal}>
      <>
        <Close onClick={closeModal} className={styles.closeIcon} />
        <ResetPassword onDone={closeModal} />
      </>
    </Modal>
  )
}

export default Component
