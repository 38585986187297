import React, { FC } from 'react'
import ArrowRightIcon from 'svg/chevron-right.svg'
import styles from './MobileCategories.module.scss'
import HR from 'components/HR'
import { useHeaderV2Context } from 'components/HeaderV2/HeaderV2Context'
import classNames from 'classnames'
import accountStyles from '../../IconActions/User/Account.module.scss'
import UserIcon from 'svg/user-login.svg'
import SessionConnector, { ConnectorProps as SessionProps } from 'containers/Session'
import UserPreferences, { ConnectorProps as UserPreferencesProps } from 'connectors/UserPreferences'
import SALink from 'components/SALink'
import debounceFunc from 'lib/debounce'
import CountryFlagIcon from 'components/HeaderV2/IconActions/CountryFlag/CountryFlagIcon'
import ModalConnector, { ConnectorProps as ModalProps } from 'containers/Modal'
import { capitalizeString } from 'lib/helpers'

type MobileCategoriesProps = SessionProps & UserPreferencesProps & ModalProps

const MobileCategories: FC<MobileCategoriesProps> = ({
  authenticated,
  fullName,
  selectedCurrency,
  availableCountries,
  userPreferences,
  userPreferencesMeasurementUnit,
  userInitials,
  openModal,
}) => {
  const { setSelectedCategory, setMobileHeaderConfig, navigationLinks } = useHeaderV2Context()
  const debounce = debounceFunc()
  const { country } = userPreferences
  const { currencyCode } = selectedCurrency
  const countryName = availableCountries ? availableCountries[country] : ''
  const capitalizedFullName = capitalizeString(fullName)

  const handleClickCategory = (categoryTitle: string) => {
    debounce(() => {
      setSelectedCategory(categoryTitle)
      setMobileHeaderConfig((previousMobileHeaderConfig) => ({
        ...previousMobileHeaderConfig,
        mobileMenuContent: 'subCategories',
      }))
    }, 200)
  }

  const handleClickAccount = () => {
    debounce(() => {
      setMobileHeaderConfig((previousMobileHeaderConfig) => ({
        ...previousMobileHeaderConfig,
        mobileTopHeader: 'account',
        mobileMenuContent: authenticated ? 'user' : 'auth',
      }))
    }, 200)
  }

  const handlePreferencesClick = () => {
    openModal('user-preferences-header-mobile')
  }

  return (
    <div className={classNames(styles.mobileCategoriesContainer)}>
      {authenticated && (
        <div className={styles.mobileCategoriesCategoryBox}>
          <div
            className={styles.mobileCategoriesCategoryArrow}
            onClick={handleClickAccount}
            data-gtm-event='top-nav'
          >
            <span
              className={classNames('defaultTypographyH5', styles.mobileCategoriesCategoryAccount)}
            >
              <span className={accountStyles.accountIconInitials}>{userInitials}</span>
              {capitalizedFullName}
            </span>
            <ArrowRightIcon className={styles.mobileCategoriesCategoryAccountIcon} />
          </div>
          <HR />
        </div>
      )}

      {navigationLinks.map(({ title, links, url }) => (
        <div className={styles.mobileCategoriesCategoryBox} key={title}>
          {links.length > 0 ? (
            <div
              data-gtm-event='top-nav'
              className={styles.mobileCategoriesCategoryArrow}
              onClick={() => handleClickCategory(title)}
            >
              <span className='defaultTypographyH5'>{title}</span>
              <ArrowRightIcon />
            </div>
          ) : (
            <SALink
              className={classNames('defaultTypographyH5', 'defaultLink')}
              href={url}
              data-gtm-event='top-nav'
            >
              {title}
            </SALink>
          )}
          <HR />
        </div>
      ))}

      {!authenticated && (
        <div className={styles.mobileCategoriesCategoryBox}>
          <div
            className={styles.mobileCategoriesCategoryArrow}
            onClick={handleClickAccount}
            data-gtm-event='top-nav'
          >
            <span
              className={classNames('defaultTypographyH5', styles.mobileCategoriesCategoryAccount)}
            >
              <div className={styles.userIconBox}>
                <UserIcon />
                <div className={styles.userIconPlus}>+</div>
              </div>
              {'Log In / Register'}
            </span>
            <ArrowRightIcon />
          </div>
          <HR />
        </div>
      )}

      <div className={styles.mobileCategoriesCategoryBox}>
        <div
          className={styles.mobileCategoriesCategoryArrow}
          onClick={handlePreferencesClick}
          data-gtm-event='top-nav'
        >
          <span
            className={classNames('defaultTypographyH5', styles.mobileCategoriesCategoryAccount)}
          >
            <div
              title='View Store Preferences Top Nav'
              className={styles.userPreferencesBox}
              data-type='country-flag-icon-top-nav'
            >
              <CountryFlagIcon customClassName={styles.userPreferencesCountryFlag} />
              <div className={styles.userPreferencesText}>
                <span>{countryName}</span>
                {'/'}
                <span>{currencyCode}</span>
                {'/'}
                <span className={styles.userPreferencesUnit}>{userPreferencesMeasurementUnit}</span>
              </div>
            </div>
          </span>
          <ArrowRightIcon />
        </div>
        <HR />
      </div>
    </div>
  )
}

export default ModalConnector(SessionConnector(UserPreferences(MobileCategories)))
