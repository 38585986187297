import React, { FC, useState } from 'react'
import SearchArworksConnector, {
  ConnectorProps as SearchArworksProps,
} from '../../connectors/SearchArtworks'
import Search from 'components/Search'
import styles from './searchArtworks.module.scss'
import CloseIcon from 'svg/close-x.svg'

type Props = SearchArworksProps & {
  placeHolder?: string
  query?: string
  showClearButton?: boolean
  updateSearch: (value: string) => void
}

const SearchArtworks: FC<Props> = (props) => {
  const { placeHolder, query, screenWidth, showClearButton, updateSearch } = props
  const [searchValue, handleSearchValue] = useState(query || '')

  const PLACEHOLDER =
    screenWidth < 600
      ? 'Search for artwork, artist ...'
      : 'Search for artwork, subject, style, medium, size, artist ...'

  return (
    <form
      className={styles.form}
      onSubmit={(ev) => {
        ev.preventDefault()
        updateSearch(searchValue)
      }}
    >
      <Search
        className='search defaultTypographySmall'
        value={searchValue}
        onChange={handleSearchValue}
        label='Search '
        placeholder={placeHolder || PLACEHOLDER}
      />

      {showClearButton && searchValue && (
        <button type='button' className={styles.clear} onClick={() => handleSearchValue('')}>
          <CloseIcon />
        </button>
      )}
    </form>
  )
}

export default SearchArworksConnector(SearchArtworks)
