import React, { useEffect, useRef, useState } from 'react'
import styles from './CategoriesCTA.module.scss'
import CategoriesCTA from './CategoriesCTA'
import CategoriesHoverContainer from './CategoriesHoverContainer'
import { useHeaderV2Context } from '../../HeaderV2Context'

const CategoriesCTAWrapper = () => {
  const parentRef = useRef<HTMLDivElement | null>(null)
  const hoverContainerRef = useRef<HTMLDivElement | null>(null)
  const overlayRef = useRef<HTMLDivElement | null>(null)
  const { setSelectedCategory, selectedCategory } = useHeaderV2Context()
  const [bodyHeight, setBodyHeight] = useState(0)

  const handleMouseMove = (event) => {
    const parent = parentRef.current
    const hoverContainer = hoverContainerRef.current
    const overlayDiv = overlayRef.current

    if (overlayDiv) {
      const mouseInsideOverlayDiv = overlayDiv.contains(event.target)
      if (mouseInsideOverlayDiv) {
        setSelectedCategory(null)
      }
    }

    if (parent && hoverContainer) {
      const mouseInsideParent = parent.contains(event.target)
      const mouseInsideHoverContainer = hoverContainer.contains(event.target)
      if (!mouseInsideParent && !mouseInsideHoverContainer) {
        setSelectedCategory(null)
      }
    }
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const handleResize = () => {
        setBodyHeight(document.body.clientHeight)
        if (window.innerWidth > 999) {
          document.addEventListener('mousemove', handleMouseMove)
        } else {
          document.removeEventListener('mousemove', handleMouseMove)
        }
      }

      handleResize()

      window.addEventListener('resize', handleResize)

      return () => {
        document.removeEventListener('mousemove', handleMouseMove)
        window.removeEventListener('resize', handleResize)
      }
    }
  }, [])

  return (
    <div className={styles.categoriesCtaWrapper} ref={parentRef}>
      <CategoriesCTA />
      <div className={styles.categoriesCtaWrapperHoverBox}>
        <CategoriesHoverContainer containerRef={hoverContainerRef} />
        {!!selectedCategory && selectedCategory !== 'Gift Card' && (
          <div
            ref={overlayRef}
            className={styles.backgroundOverlay}
            style={{ '--dynamic-body-height': `${bodyHeight}px` } as React.CSSProperties}
          />
        )}
      </div>
    </div>
  )
}

export default CategoriesCTAWrapper
