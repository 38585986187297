import React, { useEffect, useState } from 'react'
import { addStoreLocaleByCookie } from 'components/GeoLocation/helpers'
import InputLabel from 'components/SA/FormElements/InputLabel'
import Recaptcha, { getCaptchaToken } from 'components/SA/FormElements/Recaptcha'
import useFormData from 'hooks/formData'
import { postResetPassword } from '../apis'
import { resetPasswordForm, resetPasswordRules } from '../helpers'
import styles from '../Modals/Auth.module.scss'
import classNames from 'classnames'
type Props = {
  onDone?: (...args: Array<any>) => any
  onSubmit?: (...args: Array<any>) => any
}

const Component = (props: Props): React.ReactElement<React.ComponentProps<any>, any> => {
  const { onDone, onSubmit } = props
  const [state, handleState] = useState({
    error: '',
    isBlocked: false,
    resetSuccessful: false,
  })
  const { error, isBlocked, resetSuccessful } = state
  const { formData, formErrors, onChangeInput, validateFormData, updateFormErrors } = useFormData({
    initialState: resetPasswordForm,
    formRules: resetPasswordRules,
  })
  const { email } = formData
  const { email: emailError } = formErrors
  useEffect(() => {
    window.dataLayer.push({
      event: 'reset password start',
    })
  }, [])

  const handleSubmit = async (ev: React.SyntheticEvent<HTMLInputElement>) => {
    ev.preventDefault()
    const validForm = await validateFormData()

    if (!validForm) {
      return
    }

    handleState({ ...state, isBlocked: true, error: '' })
    const captchaAction = 'reset_password_submit'
    const captchaToken = await getCaptchaToken(captchaAction)
    const { messages, success } = await postResetPassword({
      captchaAction,
      captchaToken,
      email,
    })

    if (!success) {
      handleState({ ...state, isBlocked: false, error: messages.join(',') }) // Array[ 'error message' ]

      window.dataLayer.push({
        event: 'reset password error',
      })
      return
    }

    handleState({ ...state, resetSuccessful: true }) // Show Confirmation

    window.dataLayer.push({
      event: 'reset password request link',
    })

    if (onSubmit) {
      onSubmit()
    }
  }

  if (resetSuccessful) {
    return (
      <form
        noValidate
        onSubmit={(ev: React.SyntheticEvent<HTMLInputElement>) => {
          ev.preventDefault()

          if (onDone) {
            onDone()
          } else {
            const location = addStoreLocaleByCookie('/')
            window.location = location
          }
        }}
        className={styles.resetPasswordForm}
      >
        <p className={`${styles.title} defaultTypographyH3`}>Thank you!</p>

        <p className={`${styles.linkSentText} defaultTypographySmall`}>
          We sent a link to <strong>{email}</strong> to reset your password.
        </p>
        <div className={styles.doneButtonWrapper}>
          <button type='submit' className='defaultButtonGunMetal'>
            <p className='defaultTypographyCtaLarge defaultTextUppercase'>Done</p>
          </button>
        </div>
      </form>
    )
  }

  return (
    <form
      noValidate
      onSubmit={handleSubmit}
      className={`${styles.authModal} ${styles.resetPasswordForm}`}
      data-type='reset-form'
    >
      {isBlocked && <div className={styles.loader} />}

      <p className={`${styles.title} defaultTypographyH3`}>Reset Password</p>

      <p className={`${styles.enterEmailText} defaultTypographySmall`}>
        Enter the email address associated with your account and we’ll send you a link to reset your
        password.
      </p>

      <fieldset className='defaultFieldset'>
        <div className={styles.formInputContainer}>
          <InputLabel
            type='email'
            name='email'
            value={email}
            title='Email Address'
            onChange={(ev) => {
              onChangeInput(ev)
              updateFormErrors({ ...formErrors, email: '' })
            }}
            error={emailError}
            styleOverride={styles.authInputField}
          />

          <button
            type='submit'
            className={classNames(
              {
                [styles.buttonError]: emailError,
              },
              'defaultButtonGunMetal'
            )}
          >
            <p className='defaultTypographyCtaLarge defaultTextUppercase'>Send Link</p>
          </button>
        </div>

        {error && <p className={`${styles.error} defaultTypographyXSmall`}>{error}</p>}

        <Recaptcha styleOverride={styles.loginRecaptcha} />
      </fieldset>
    </form>
  )
}

export default Component
