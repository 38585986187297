import React from 'react'
import { fetchAllClientData } from 'apis/client'
import GeoLocationConnector from 'connectors/GeoLocation'
import { setUserPreferencesCookie } from 'components/Preferences/apis'
import { createNewBrowserPath, getCurrentBrowserPath } from 'components/GeoLocation/helpers'
import { setClientStorage } from 'store/middleware/client'
import { getCartData } from 'components/CartUrgencyBar/helper'
import Modal from 'components/SA/Modal/v2'
import styles from './Auth.module.scss'
import Close from 'svg/close-x.svg'
import Login from '../Forms/Login'

const Component = (
  props: Record<string, any>
): React.ReactElement<React.ComponentProps<any>, any> => {
  const { closeModal, resetPasswordModal, registerModal, updateSession } = props

  const handleSubmit = async () => {
    // We need to take all USER preferences and update COOKIE - respect USER SESSION data
    await setUserPreferencesCookie({
      override: true,
    })
    // Fetch Session and Cart data
    const { payload: allClientData, success } = await fetchAllClientData()

    if (!success) {
      console.error('Problem Fetching Session')
      return
    }

    setClientStorage(allClientData)
    await getCartData()
    const { cart, cookie, session } = allClientData
    const userPreferencesIso2CountryCode = cookie.userPreferences.country
    const urlPrefix = `en-${userPreferencesIso2CountryCode.toLowerCase()}`
    const newBrowserPath = createNewBrowserPath(urlPrefix)
    const currentBrowserPath = getCurrentBrowserPath()

    if (newBrowserPath !== currentBrowserPath) {
      window.location = newBrowserPath
      return
    }

    updateSession({
      cart: cart.count || 0,
      session,
    })
    closeModal()
  }

  return (
    <Modal name='login' closeModal={closeModal} layout='standard' styleOverride={styles.authModal}>
      <>
        <Close onClick={closeModal} className={styles.closeIcon} />
        <Login
          message='You were logged out. Please log in to continue.'
          onClickRegister={() => {
            registerModal()
          }}
          onClickResetPassword={() => {
            resetPasswordModal()
          }}
          onSubmit={handleSubmit}
        />
      </>
    </Modal>
  )
}

export default GeoLocationConnector(Component)
