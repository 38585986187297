import React from 'react'
import UserPreferencesConnector, { ConnectorProps } from 'connectors/UserPreferences'
import { convertDefaultToLocaleCurrency, formatPrice } from './helpers'

type LocaleCurrencyProps = ConnectorProps & {
  price: number
  decimals?: boolean
}

const LocaleCurrency = ({ decimals, price, selectedCurrency }: LocaleCurrencyProps) => {
  return (
    <>
      {formatPrice(
        convertDefaultToLocaleCurrency(price, selectedCurrency, decimals),
        selectedCurrency,
        decimals
      )}
    </>
  )
}

export default UserPreferencesConnector(LocaleCurrency)
