import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import { getScreenWidth, getIsMobileScreenWidth, getScreenIsReady } from 'selectors/screen'
import { getIsMobileTraffic } from 'selectors/shared/mobileTraffic'
import { Actions as ScreenActions } from 'actions/screen'

// Do NOT pass in extra PROPS, this file is ONLY used to fire INITIAL Setup
const mapStateToProps = createStructuredSelector({
  screenWidth: getScreenWidth,
  isMobile: getIsMobileTraffic,
  isMobileScreenWidth: getIsMobileScreenWidth,
  isScreenReady: getScreenIsReady,
})

const mapDispatchToProps = (dispatch) => ({
  updateScreenSize: (payload: Record<string, any>) =>
    dispatch(ScreenActions.UPDATE_SCREEN_SIZE(payload)),
})

export type ConnectorProps = {
  screenWidth: number
  isMobile: boolean
  isMobileScreenWidth: boolean
  isScreenReady: boolean
  updateScreenSize: (...args: Array<any>) => any
}

export default connect(mapStateToProps, mapDispatchToProps)
