import React from 'react'
import SALink from 'components/SALink'
import styles from './MailSection.module.scss'
import InstagramIcon from 'svg/instagram-icon.svg'
import TiktokIcon from 'svg/tiktok-icon.svg'
import FacebookIcon from 'svg/facebook-icon.svg'
import PinterestIcon from 'svg/pinterest-icon.svg'
import YoutubeIcon from 'svg/youtube-icon.svg'
import AppStoreIcon from 'svg/app-store-icon.svg'

const commonLinkProps = {
  'data-gtm-event': 'footer',
  rel: 'noopener noreferrer',
  target: '_blank',
}

const SocialMedia = () => {
  return (
    <div className={styles.socialMediaWrapper}>
      <div className={styles.socialMediaContainer} data-type='social-media-container'>
        <SALink
          aria-label='Saatchi Art - Instagram'
          href='http://instagram.com/saatchiart'
          {...commonLinkProps}
        >
          <InstagramIcon />
        </SALink>
        <SALink
          aria-label='Saatchi Art - Tiktok'
          href='https://www.tiktok.com/@saatchi_art'
          {...commonLinkProps}
        >
          <TiktokIcon />
        </SALink>
        <SALink
          aria-label='Saatchi Art - Facebook'
          href='http://www.facebook.com/saatchionline'
          {...commonLinkProps}
        >
          <FacebookIcon />
        </SALink>
        <SALink
          aria-label='Saatchi Art - Pinterest'
          href='http://pinterest.com/saatchiart'
          {...commonLinkProps}
        >
          <PinterestIcon />
        </SALink>
        <SALink
          aria-label='Saatchi Art - Youtube'
          href='https://www.youtube.com/channel/UCqfUz6UyK4kKVsnTyPoWZJw'
          {...commonLinkProps}
        >
          <YoutubeIcon />
        </SALink>
      </div>
      <div>
        {navigator.userAgent.match(/iPhone|iPad|iPod/i) && (
          <SALink
            alt='Download on the App Store'
            aria-label='App Store'
            href='https://apps.apple.com/us/app/saatchi-art-buy-original-art/id940252790'
            {...commonLinkProps}
          >
            <AppStoreIcon />
          </SALink>
        )}
      </div>
    </div>
  )
}

export default SocialMedia
