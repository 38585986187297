import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'

import { getScreenWidth } from 'selectors/screen'

const mapStateToProps = createStructuredSelector({
  screenWidth: getScreenWidth,
})

const mapDispatchToProps = (dispatch) => ({})

export type ConnectorProps = {
  screenWidth: number
}

export default connect(mapStateToProps, mapDispatchToProps)
