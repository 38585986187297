import type { ValidationRuleset } from 'lib/validation'
import { emailValidator, notEmptyValidator } from 'lib/validation'
export const RULES = {
  COUNT_REGEX: /^.{8,100}$/,
  // should contain 8 - 100 characters
  DIGIT_REGEX: /^(?=.*\d)/,
  // should contain at least one digit
  SPECIAL_CHARACTER_REGEX: /^(?=.*[!"'`#$%&*+,-.~^_/:;<=>?@()[\]{}|])/,
  // should contain at least one special character
  LOWERCASE_REGEX: /^(?=.*[a-z])/,
  // should contain at least one lower case
  UPPERCASE_REGEX: /^(?=.*[A-Z])/,
  // should contain at least one upper case
  // All Rules
  PASSWORD_REGEX: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!"'`#$%&*+,-.~^_/:;<=>?@()[\]{}|]).{8,100}$/,  
}

const validName = (value: string) => {
  // const regex = /^[a-zA-Z]+$/ Old Logic
  // No Special Characters
  const regex = /^[^.*|:<>[\]{}`\\\/();@&$]+$/  

  const valid = regex.test(value)
  return valid
}

export const VALIDATION_RULES: ValidationRuleset = {
  firstName: [
    {
      validate: validName,
      message: () => 'First name can only include A-Z characters.',
    },
    { ...notEmptyValidator, message: () => 'First name is required.' },
  ],
  lastName: [
    {
      validate: validName,
      message: () => 'Last name can only include A-Z characters.',
    },
    { ...notEmptyValidator, message: () => 'Last name is required.' },
  ],
  email: [
    {
      validate: (value: string) => emailValidator.validate(value),
      message: () => 'Please enter a valid email.',
    },
    { ...notEmptyValidator, message: () => 'Email address is required.' },
  ],
  password: [
    {
      validate: (value: string) => {
        return !!value && !!value.match(RULES.PASSWORD_REGEX)
      },
      message: () => 'Password does not meet requirements.',
    },
    { ...notEmptyValidator, message: () => 'Password is required.' },
  ],
}
export const loginForm = {
  email: '',
  password: '',
}
export const loginRules: ValidationRuleset = {
  email: VALIDATION_RULES.email,
  password: [{ ...notEmptyValidator, message: () => 'Password is required.' }],
}
export const registrationForm = {
  email: '',
  firstName: '',
  lastName: '',
  newsletterSignup: true,
  password: '',
}
export const registrationRules: ValidationRuleset = VALIDATION_RULES
export const resetPasswordForm = {
  email: '',
}
export const resetPasswordRules: ValidationRuleset = {
  email: VALIDATION_RULES.email,
}
export const createPasswordForm = {
  password: '',
}
export const createPasswordRules: ValidationRuleset = {
  password: [
    {
      validate: (value: string) => !!value.match(RULES.PASSWORD_REGEX),
      message: () => 'Password does not meet requirements.',
    },
    { ...notEmptyValidator, message: () => 'Password is required.' },
  ],
}