/* This is used by NAVIGATION */
import React, { FC } from 'react'
import AuthenticationConnect, {
  ConnectorProps as AuthenticationProps,
} from 'connectors/Authentication'
import Register from './Register'
import ResetPassword from './ResetPassword'
import PromptLogin from './PromptLogin'
import Login from './Login'

const MODAL_NAMES = ['register', 'reset', 'prompt', 'login']
const MODAL_CONTENT = {
  ['register']: Register,
  ['reset']: ResetPassword,
  ['prompt']: PromptLogin,
  ['login']: Login,
}

type AuthenticationModalsProps = {
  reloadPage: boolean
} & AuthenticationProps

const AuthenticationModals: FC<AuthenticationModalsProps> = (props) => {
  const { reloadPage, updateSession, name, openModal } = props

  if (!MODAL_NAMES.includes(name)) {
    return null
  }

  const ModalComponent = MODAL_CONTENT[name]

  return (
    <ModalComponent
      type={name}
      closeModal={() => openModal('')}
      loginModal={() => openModal('login')}
      registerModal={() => openModal('register')}
      resetPasswordModal={() => openModal('reset')}
      updateSession={(data) => {
        updateSession(data)

        if (reloadPage) {
          // On Login - Reload the page
          window.location.reload()
        }
      }}
    />
  )
}

export default AuthenticationConnect(AuthenticationModals)
