import React from 'react'
import { filterObject } from 'lib/helpers'
import { Checkbox, Icon, CheckMark, Text } from './Styles'

type Props = Record<string, any>

const Component = (props: Props) => {
  const { checked, readOnly, text, children } = props
  return (
    <Checkbox readOnly={!!readOnly} data-type='checkbox'>
      <input
        onChange={
          () => {}
          /* React Warning - when no Function */
        }
        type='checkbox'
        {...filterObject(props, ['children'])}
        aria-checked={checked}
      />

      <Icon aria-hidden='true'>{checked && <CheckMark checked={!!checked} />}</Icon>

      {text && (
        <span className='defaultTypographyTiny' data-type='checkbox-text'>
          {text}
        </span>
      )}

      {children || ''}
    </Checkbox>
  )
}

export default Component
