import React from 'react'
import useScript from 'hooks/loadScript'
const { RECAPTCHA_WEBSITE_KEY_ID } = process.env

/* NOTES -> `docs/services/Captcha.md` */
declare let grecaptcha
const SCRIPT_URL = `https://www.google.com/recaptcha/enterprise.js?render=${RECAPTCHA_WEBSITE_KEY_ID ||
  ''}&trustedtypes=true`
export const getCaptchaToken = async (action: string): Promise<string> => {
  return new Promise((resolve) => {
    grecaptcha.enterprise.ready(() => {
      grecaptcha.enterprise
        .execute(RECAPTCHA_WEBSITE_KEY_ID, {
          action,
        })
        .then((token) => {
          return resolve(token)
        })
    })
  })
}
type Props = {
  customId?: string // Future Proof for MULTIPLE captcha's
  styleOverride?: string
}

const Component = (props: Props): React.ReactElement<React.ComponentProps<any>, any> => {
  const { customId, styleOverride } = props
  const widgetId = customId || 'recaptcha'
  useScript({
    async: true,
    name: 'captcha-script',
    src: SCRIPT_URL,
  })
  return (
    <div id={widgetId} className={`recaptcha ${styleOverride}`}>
      <p className='defaultTypographyTiny'>
        This site is protected by reCAPTCHA and the Google{' '}
        <a className='defaultTypographyTiny' href='https://policies.google.com/privacy'>
          Privacy Policy
        </a>{' '}
        and{' '}
        <a className='defaultTypographyTiny' href='https://policies.google.com/terms'>
          Terms of Service
        </a>{' '}
        apply.
      </p>
    </div>
  )
}

export default Component
