import React from 'react'
import { Search, Input, MagGlass } from './styles'
type Props = {
  className?: string
  label?: string
  name?: string
  onChange: (...args: Array<any>) => any
  placeholder?: string
  value: string
}
export default (props: Props) => {
  const {
    className,
    name = 'search',
    placeholder = 'Search...',
    value = '',
    onChange,
    label = '',
  } = props
  return (
    <Search className={className}>
      <Input
        aria-label={`Search ${label}`}
        autoComplete='off'
        name={name}
        onChange={(ev) => onChange(ev.target.value)}
        placeholder={placeholder}
        type='text'
        value={value}
      />
      <MagGlass data-type='mag' />
    </Search>
  )
}
