import React, { FC, ReactNode, useState } from 'react'
import AccordionItem from './AccordionItem'
import styles from './Accordion.module.scss'

export type AccordionItemType = {
  title: string
  content: ReactNode
  headerCustomStyle?: string
  contentCustomStyle?: string
  itemCustomStyle?: string
}

type AccordionProps = {
  items: ReadonlyArray<AccordionItemType>
  headerCustomStyle?: string
  contentCustomStyle?: string
  itemCustomStyle?: string
}

const Accordion: FC<AccordionProps> = ({
  items,
  headerCustomStyle,
  contentCustomStyle,
  itemCustomStyle,
}) => {
  const [activeIndex, setActiveIndex] = useState<ReadonlyArray<string>>([])

  const handleChangeIndex = (index) => {
    setActiveIndex((prevValue) => {
      if (prevValue.includes(index)) {
        return prevValue.filter((indexToFilter) => indexToFilter !== index)
      } else {
        return [...prevValue, index]
      }
    })
  }

  return (
    <div className={styles.accordionContainer}>
      {items.map((item, index) => (
        <AccordionItem
          key={index.toString()}
          title={item.title}
          headerCustomStyle={headerCustomStyle}
          contentCustomStyle={contentCustomStyle}
          itemCustomStyle={itemCustomStyle}
          activeIndex={activeIndex}
          onChangeIndex={handleChangeIndex}
        >
          {item.content}
        </AccordionItem>
      ))}
    </div>
  )
}

export default Accordion
